<template>
    <div class="for-female">
        <div class="desc">
            <p>갑자기 약속을 잡고 싶을 때,</p>
            <p>오늘만나로 안전하게 만나보세요!</p>
        </div>
        <div class="male-required">
            <div class="snackbar m-b-24">
                <span class="f-13">남성회원 이용 조건</span>
            </div>
            <div class="req-desc">
                <p>직장, 직업, 학교, 신분증, 실명 중</p>
                <p class="c-primary f-bold">최소 2개 이상 인증한 남성 회원</p>
                <div class="center">
                    <img
                        class="verification-img"
                        :src="require('@/assets/images/today-meeting/female-verification@2x.png')"
                    />
                </div>
            </div>
            <div class="popular-male">
                <p style="text-align: center" class="f-14 m-b-12">
                    외모, 스펙 등을 고려한
                    <span class="c-primary f-bold">인기 남성 회원</span>
                </p>
                <div class="center">
                    <img class="m-b-30" :src="require('@/assets/images/today-meeting/female-users@2x.png')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActivateForFemale',
}
</script>

<style scoped lang="scss">
.for-female {
    color: $grey-09;
    @include f-regular;

    img {
        width: 80%;
        height: auto;
    }

    .desc {
        padding: 0 16px 16px 16px;
        border-bottom: 1px solid $grey-02;
        font-size: 15px;
    }
    .male-required {
        padding-top: 22px;
        justify-content: space-between;
        align-items: center;
        @include flex;

        .snackbar {
            position: static;
            height: 28px;
            box-shadow: none;
            border-radius: 6px;

            @include center;
        }
        .req-desc {
            font-size: 14px;

            p {
                text-align: center;
            }
            .verification-img {
                margin: 12px 0 30px 0;
            }
        }
    }
}
</style>
